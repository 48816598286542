// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shirt-detail-js": () => import("../src/pages/shirtDetail.js" /* webpackChunkName: "component---src-pages-shirt-detail-js" */),
  "component---src-pages-sticker-detail-js": () => import("../src/pages/stickerDetail.js" /* webpackChunkName: "component---src-pages-sticker-detail-js" */),
  "component---src-pages-success-js": () => import("../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

